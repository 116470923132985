<template>
  <v-app-bar
    id="core-header"
    fixed
    app
    color="primary"
    flat
    height="85"
    style="width: auto; z-index: 11"
  >
    <v-btn
      fab
      small
      class="accent"
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <div class="mx-2" />

    <v-tooltip
      v-if="env.name !== 'production'"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-icon
          large
          :color="env.color"
          v-on="on"
        >
          {{ env.icon }}
        </v-icon>
      </template>
      <span>{{ env.name }} environment</span>
    </v-tooltip>

    <div
      v-if="env.name !== 'production'"
      class="mx-2"
    />

    <v-toolbar-title class="hidden-sm-and-down font-italic text-h5">
      {{ ($route.meta && $route.meta.title ? $route.meta.title : $route.name) | pascalToSpace }}
    </v-toolbar-title>

    <v-spacer />

    <!-- Search (good code) -->
    <!-- <v-text-field hide-details class="mb-2 primary" style="max-width: 165px;">
      <template v-slot:label>
        <v-icon>mdi-magnify</v-icon>
      </template>
    </v-text-field>

    <div class="mx-2" /> -->

    <v-menu
      v-model="settingsMenu"
      bottom
      right
      offset-y
      :close-on-content-click="false"
      content-class="v-settings"
      nudge-left="8"
      origin="top"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          id="settings"
          min-width="0"
          fab
          text
          depressed
          v-on="on"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>

      <v-card
        class="text-center mb-0"
        width="300"
      >
        <v-card-text>
          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              <label class="font-weight-bold">
                <v-icon left>mdi-theme-light-dark</v-icon>
                Dark Mode
              </label>
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="isDarkMode"
                class="ma-0 pa-0"
                color="primary"
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>

    <div class="mx-1" />

    <v-menu
      bottom
      offset-y
      origin="top"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          min-width="0"
          fab
          text
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            v-if="notifications.length"
            color="red"
            overlap
            :content="notifications.length"
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <v-icon
            v-else
            @click.native.stop
          >
            mdi-bell
          </v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <div class="mx-1" />

    <v-menu
      v-model="profileMenu"
      bottom
      right
      offset-y
      :close-on-content-click="false"
      content-class="v-settings"
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          id="profile"
          min-width="0"
          fab
          text
          depressed
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-card
        class="text-center mb-0"
        width="300"
      >
        <v-card-text>
          <template v-for="(p, i) in profile.filter(x => !x.hasClaim || hasClaimKV(x.hasClaim))">
            <v-row
              :key="`item-${i}`"
              align="center"
              no-gutters
            >
              <v-divider
                v-if="p.divider"
                :key="`divider-${i}`"
                class="my-4 secondary"
              />

              <v-col
                v-else-if="p.title"
                :key="`item-${i}`"
                cols="12"
                class="my-1"
              >
                <v-btn
                  block
                  color="secondary"
                  @click="profileAction(p.title)"
                >
                  <v-icon
                    v-if="p.icon"
                    left
                  >
                    {{ p.icon }}
                  </v-icon>
                  {{ p.title }}
                </v-btn>
              </v-col>

              <v-col
                v-else-if="p.label"
                :key="`label-${i}`"
                cols="12"
                class="my-1"
              >
                <label class="font-weight-bold">{{ p.label }}</label>
              </v-col>
            </v-row>
          </template>

          <!-- <v-row
            v-if="profileSwap"
            align="center"
            no-gutters
            class="my-2"
          >
            <v-col cols="12">
              <label class="font-weight-bold">{{ profileSwap.title }}</label>
            </v-col>

            <v-col
              cols="12"
              class="my-1"
            >
              <v-select
                v-model="profileSwap.package"
                :items="profileSwap.packageSelect"
                label="Package"
                solo
                dense
                clearable
                full-width
                hide-details
                color="primary"
              />
            </v-col>
            <v-col
              cols="12"
              class="my-1"
            >
              <v-select
                v-model="profileSwap.role"
                :items="profileSwap.roleSelect"
                label="Role"
                solo
                dense
                clearable
                full-width
                hide-details
                color="primary"
              />
            </v-col>
            <v-col
              cols="12"
              class="my-1"
            >
              <v-btn
                block
                color="primary"
                :disabled="!profileSwap.package || !profileSwap.role"
                @click="swapProfile()"
              >
                <v-icon left>
                  {{ profileSwap.icon }}
                </v-icon>
                {{ profileSwap.btn }}
              </v-btn>
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
    </v-menu>

    <div class="mx-1" />

    <v-btn
      id="profile"
      min-width="0"
      fab
      text
      depressed
      @click="setChatDrawer(!chatDrawer)"
    >
      <v-icon>mdi-{{ chatDrawer ? "close" : "help" }}</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from 'vuetify/lib'

// Utilities
import { packages, roles } from '@/shared/models/auth'
import claims from '@/shared/models/auth/claims'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'CoreAppBar',

  components: {
    // Settings,
    AppBarItem: {
      render (h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-8': hover
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              )
            }
          }
        })
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    settingsMenu: false,
    profileMenu: false,
    helpMenu: false,
    notifications: [
      // 'Mailing results are back!',
      // 'You have 3 new tasks',
      // 'John is waiting for your approval on an email test',
      // 'What kind of notifications do you want to see here?'
    ],
    profile: [
      { id: 'greeting', label: '' },
      { title: 'Profile', icon: 'mdi-account-circle' },
      { title: 'Account', icon: 'mdi-account-multiple', hasClaim: claims.AD_AS },
      { title: 'Permissions', icon: 'mdi-account-lock' },
      { title: 'Release Notes', icon: 'mdi-tag' },
      { divider: true },
      { title: 'Log out', icon: 'mdi-logout' }
    ],
    // profileSwap: null,
    env: {
      name: process.env.NODE_ENV,
      icon: process.env.VUE_APP_ICON,
      color: process.env.VUE_APP_ICON_COLOR,
      isDebug: process.env.VUE_APP_IS_DEBUG === 'true'
    }
  }),

  computed: {
    ...mapState(['drawer', 'chatDrawer']),
    ...mapGetters('auth', ['getFullName', 'hasClaimKV']),
    fullName () {
      return this.getFullName()
    },
    isDarkMode: {
      get () {
        return this.$store.getters.isDarkMode()
      },
      set (value) {
        this.$store.dispatch('setIsDarkMode', value)
      }
    }
  },

  watch: {
    fullName: {
      immediate: true,
      handler (newValue, oldValue) {
        this.profile.find(x => x.id === 'greeting').label = `Hello ${newValue ||
          oldValue}!`
      }
    }
  },

  created () {
    // if (this.env.isDebug) {
    //   this.profileSwap = {
    //     title: 'Swap to User:',
    //     icon: 'mdi-account-switch',
    //     btn: 'Swap',
    //     package: null,
    //     packageSelect: packages,
    //     role: null,
    //     roleSelect: roles
    //   }
    // }
  },

  methods: {
    ...mapMutations(['setDrawer', 'setChatDrawer']),
    ...mapActions('auth', ['logIn', 'logOut']),
    ...mapActions([
      'addNotification',
      'clearNotification',
      'clearAllNotifications'
    ]),
    profileAction (title) {
      switch (title) {
        case 'Log out':
          const data = this.logOut()
          this.$router.push({ name: 'AppLogin' })
          break
        case 'Permissions':
          this.profileMenu = false
          this.$router.push({ name: 'Permissions' })
          break
        case 'Profile':
          this.profileMenu = false
          this.$router.push({ name: 'Profile' })
          break
        case 'Account':
          this.profileMenu = false
          this.$router.push({ name: 'Account' })
          break
        case 'Release Notes':
          this.profileMenu = false
          this.$router.push({ name: 'ReleaseNotes' })
          break
      }
    }
    // async swapProfile () {
    //   if (!this.env.isDebug) return
    //   this.logOut()
    //   const user = await this.logIn({
    //     email: `${this.profileSwap.package}-${this.profileSwap.role}@mailinator.com`, // TODO: remove username/password helpers for prod/pull from backend
    //     password: 'MM5t3st!12$'
    //   })
    //   if (user.errorResponse) {
    //     this.$store.commit('setGlobalSnackbar', {
    //       message: user.errorResponse,
    //       color: 'error'
    //     })
    //     return
    //   }
    //   this.profileMenu = false
    //   this.$router.push({ name: 'CampaignDashboard' })
    // }
  }
}

</script>

<style lang="sass">
  .v-settings
    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>
