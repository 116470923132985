var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticStyle: { width: "auto", "z-index": "11" },
      attrs: {
        id: "core-header",
        fixed: "",
        app: "",
        color: "primary",
        flat: "",
        height: "85"
      }
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "accent",
          attrs: { fab: "", small: "" },
          on: {
            click: function($event) {
              _vm.$vuetify.breakpoint.smAndDown
                ? _vm.setDrawer(!_vm.drawer)
                : _vm.$emit("input", !_vm.value)
            }
          }
        },
        [
          _vm.value
            ? _c("v-icon", [_vm._v("\n      mdi-view-quilt\n    ")])
            : _c("v-icon", [_vm._v("\n      mdi-dots-vertical\n    ")])
        ],
        1
      ),
      _c("div", { staticClass: "mx-2" }),
      _vm.env.name !== "production"
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            { attrs: { large: "", color: _vm.env.color } },
                            on
                          ),
                          [
                            _vm._v(
                              "\n        " + _vm._s(_vm.env.icon) + "\n      "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1670329349
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm.env.name) + " environment")])]
          )
        : _vm._e(),
      _vm.env.name !== "production"
        ? _c("div", { staticClass: "mx-2" })
        : _vm._e(),
      _c(
        "v-toolbar-title",
        { staticClass: "hidden-sm-and-down font-italic text-h5" },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm._f("pascalToSpace")(
                  _vm.$route.meta && _vm.$route.meta.title
                    ? _vm.$route.meta.title
                    : _vm.$route.name
                )
              ) +
              "\n  "
          )
        ]
      ),
      _c("v-spacer"),
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            right: "",
            "offset-y": "",
            "close-on-content-click": false,
            "content-class": "v-settings",
            "nudge-left": "8",
            origin: "top",
            transition: "scale-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var attrs = ref.attrs
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          id: "settings",
                          "min-width": "0",
                          fab: "",
                          text: "",
                          depressed: ""
                        }
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-cog")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.settingsMenu,
            callback: function($$v) {
              _vm.settingsMenu = $$v
            },
            expression: "settingsMenu"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "text-center mb-0", attrs: { width: "300" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", "no-gutters": "" } },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c(
                          "label",
                          { staticClass: "font-weight-bold" },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("mdi-theme-light-dark")
                            ]),
                            _vm._v("\n              Dark Mode\n            ")
                          ],
                          1
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-switch", {
                            staticClass: "ma-0 pa-0",
                            attrs: { color: "primary", "hide-details": "" },
                            model: {
                              value: _vm.isDarkMode,
                              callback: function($$v) {
                                _vm.isDarkMode = $$v
                              },
                              expression: "isDarkMode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "mx-1" }),
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            "offset-y": "",
            origin: "top",
            transition: "scale-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var attrs = ref.attrs
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            "min-width": "0",
                            fab: "",
                            text: "",
                            depressed: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm.notifications.length
                        ? _c(
                            "v-badge",
                            {
                              attrs: {
                                color: "red",
                                overlap: "",
                                content: _vm.notifications.length
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-bell")])],
                            1
                          )
                        : _c(
                            "v-icon",
                            {
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [_vm._v("\n          mdi-bell\n        ")]
                          )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c("v-list", { attrs: { tile: false, nav: "" } }, [
            _c(
              "div",
              _vm._l(_vm.notifications, function(n, i) {
                return _c(
                  "app-bar-item",
                  { key: "item-" + i },
                  [
                    _c("v-list-item-title", {
                      domProps: { textContent: _vm._s(n) }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "mx-1" }),
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            right: "",
            "offset-y": "",
            "close-on-content-click": false,
            "content-class": "v-settings",
            origin: "top right",
            transition: "scale-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var attrs = ref.attrs
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          id: "profile",
                          "min-width": "0",
                          fab: "",
                          text: "",
                          depressed: ""
                        }
                      },
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-account")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.profileMenu,
            callback: function($$v) {
              _vm.profileMenu = $$v
            },
            expression: "profileMenu"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "text-center mb-0", attrs: { width: "300" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._l(
                    _vm.profile.filter(function(x) {
                      return !x.hasClaim || _vm.hasClaimKV(x.hasClaim)
                    }),
                    function(p, i) {
                      return [
                        _c(
                          "v-row",
                          {
                            key: "item-" + i,
                            attrs: { align: "center", "no-gutters": "" }
                          },
                          [
                            p.divider
                              ? _c("v-divider", {
                                  key: "divider-" + i,
                                  staticClass: "my-4 secondary"
                                })
                              : p.title
                              ? _c(
                                  "v-col",
                                  {
                                    key: "item-" + i,
                                    staticClass: "my-1",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          block: "",
                                          color: "secondary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.profileAction(p.title)
                                          }
                                        }
                                      },
                                      [
                                        p.icon
                                          ? _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(p.icon) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(p.title) +
                                            "\n              "
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : p.label
                              ? _c(
                                  "v-col",
                                  {
                                    key: "label-" + i,
                                    staticClass: "my-1",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v(_vm._s(p.label))]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "mx-1" }),
      _c(
        "v-btn",
        {
          attrs: {
            id: "profile",
            "min-width": "0",
            fab: "",
            text: "",
            depressed: ""
          },
          on: {
            click: function($event) {
              return _vm.setChatDrawer(!_vm.chatDrawer)
            }
          }
        },
        [
          _c("v-icon", [
            _vm._v("mdi-" + _vm._s(_vm.chatDrawer ? "close" : "help"))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }